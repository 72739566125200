import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from "react-icons/fi";
import { MdOutlineVisibility } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";


export default function NewHeroVideo({ Text, Slogan, urlVideo }) {

    const { rpdata } = useContext(GlobalDataContext);

    return (
        <section>
            <div className="relative pb-8 md:h-[120vh] h-[130vh]">
                <div>
                    <video

                        playsInline
                        autoPlay
                        muted
                        loop
                        className="w-full md:h-full object-cover absolute top-0 bottom-0 left-0 right-0 h-full"
                    >
                        <source
                            src={urlVideo}
                            type="video/mp4"
                        />
                    </video>

                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center holder bg-gray-900/75">
                        <div className="z-10 max-w-5xl px-4 mx-auto text-center">
                            <h2
                                className="mt-20 mb-4 text-3xl font-bold leading-tight text-white md:text-4xl md:leading-tight lg:text-7xl lg:leading-tight g">
                                {Slogan}
                            </h2>
                            <p className="mb-8 text-base leading-8 text-gray-200 lg:text-xl">
                                {Text}
                            </p>
                            <div className="px-20 md:px-0">
                                <a className="block px-5 py-3 text-md font-semibold text-center text-white hover:text-indigo-500 transition duration-200 bg-2 border border-white rounded md:inline-block hover:bg-indigo-50 hover:border-indigo-50"
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                >
                                    Contact us
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3  relative mt-0 lg:-mt-[90px]  z-10  rounded">
                <div className="w-full p-8 mb-10 text-center transition-all bg-2 shadow lg:mb-0">
                    <div className="inline-block p-4 mb-6 -mt-16 bg-gray-100 rounded-full">
                        <FiTarget className="text-[40px] textColor2" />
                    </div>
                    <h2 className="mb-4 text-2xl font-semibold text-gray-100 dark:text-white"> {rpdata?.dbValues?.[0]?.title}</h2>
                    <p className="text-base text-white dark:text-white">
                        {rpdata?.dbValues?.[0].description}
                    </p>
                </div>
                <div className="w-full p-8 mb-10 text-center transition-all bg2 shadow lg:mb-0 ">
                    <div className="inline-block p-4 mb-6 -mt-16 bg-gray-100 rounded-full">
                        <MdOutlineVisibility className="text-[40px] textColor2" />
                    </div>
                    <h2 className="mb-4 text-2xl font-semibold text-gray-100 dark:text-white"> {rpdata?.dbValues?.[1]?.title}</h2>
                    <p className="text-base text-gray-300 dark:text-gray-300">
                        {rpdata?.dbValues?.[1].description}
                    </p>
                </div>
                <div className="w-full p-8 mb-10 text-center transition-all bg-2 shadow lg:mb-0 ">
                    <div className="inline-block p-4 mb-6 -mt-16 bg-gray-100 rounded-full">
                        <FaHandshake className="text-[40px] textColor2" />
                    </div>
                    <h2 className="mb-4 text-2xl font-semibold text-gray-100 dark:text-white">{rpdata?.dbValues?.[2]?.title}</h2>
                    <p className="text-base text-white dark:text-white">
                        {rpdata?.dbValues?.[2].description}
                    </p>
                </div>

            </div>
        </section>



    );

}